import format from 'number-format.js';

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case 'GEL':
      return '₾';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'RUB':
      return '₽';
    case 'TRY':
      return '₺';
    default:
      return currency;
  }
}

export const AMOUNT_NUMBER_FORMAT = '#,##0.00';

export function round(value: number, decimals: number): string {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
}

export function formatAmount(amount: number | bigint, fractionDigitsCount: number): string {
  const options = {
    minimumFractionDigits: fractionDigitsCount,
    maximumFractionDigits: fractionDigitsCount,
  };

  return Number(amount).toLocaleString('en', options);
}

export function formatAmountWithCurrency(amount: number | bigint, currency: string): string {
  return formatAmount(amount, 2) + ' ' + currency;
}

export function formatAmount2(amount: number | bigint): string {
  return formatAmount(amount, 2);
}

export function formatPercent2(percent: number | null | undefined): string {
  if (percent == null) {
    return '';
  }

  return format(AMOUNT_NUMBER_FORMAT, percent) + ' %';
}
