import {SectionProductSelect} from './SectionProductSelect';
import {SectionPersonalInfo} from './SectionPersonalInfo';
import {SectionCalculator} from './SectionCalculator';
import {SectionReview} from './SectionReview';
import {StatementState} from './store';
import {z, ZodType} from 'zod';
import {LeasingProductDTO, LeasingProductParamConfigDTO} from './model';
import {TFunction} from 'i18next';
import {SectionSuccessWithReview} from './SectionSuccessWithReview';
import {SectionConfirmations} from './SectionConfirmations';

export const STATEMENT_STEPS = [
  'STEP_PRODUCT_CHOICE',
  'STEP_CALCULATION',
  'STEP_PERSONAL_INFO',
  'STEP_CONFIRMATION',
  'STEP_REVIEW',
];

export function getStepContent(step: number, goForward: () => void) {
  switch (step) {
    case 0:
      return <SectionProductSelect goForward={goForward} />;
    case 1:
      return <SectionCalculator />;
    case 2:
      return <SectionPersonalInfo />;
    case 3:
      return <SectionConfirmations />;
    case 4:
      return <SectionReview />;
    case 5:
      return <SectionSuccessWithReview />;
    default:
      throw new Error('Unknown step');
  }
}

export function isStepValid(step: number, state: StatementState) {
  switch (step) {
    case 0:
      return state.selectedProduct !== null;
    case 1:
      return state.calculatorStepValid;
    case 2:
      return state.subjectStepValid;
    case 3:
      return state.confirmationStepValid;
    case 4:
      return state.creditInfoAccepted;
    default:
      return false;
  }
}

function setMinMax(
  arr: LeasingProductParamConfigDTO[],
  t: TFunction<'translation'>,
  customMessages?: string[]
) {
  const params = arr[0];
  let result: ZodType = z.number();

  if (params.minValue && params.maxValue && !customMessages) {
    result = result.and(
      z
        .number()
        .min(params.minValue, {
          message: t('VALIDATION_NUMBER_BETWEEN_X_AND_Y', {
            x: params.minValue,
            y: params.maxValue,
          })!,
        })
        .max(params.maxValue, {
          message: t('VALIDATION_NUMBER_BETWEEN_X_AND_Y', {
            x: params.minValue,
            y: params.maxValue,
          })!,
        })
    );

    return result;
  }

  if (params.minValue !== undefined && params.minValue !== null) {
    result = result.and(
      z.number().min(params.minValue, {
        message: t(customMessages?.at(0) ?? 'VALIDATION_NUMBER_MORE_THAN_X', {
          x: params.minValue,
        })!,
      })
    );
  }

  if (params.maxValue !== undefined && params.maxValue !== null) {
    result = result.and(
      z.number().max(params.maxValue, {
        message: t(customMessages?.at(1) ?? 'VALIDATION_NUMBER_LESS_THAN_X', {
          x: params.maxValue,
        })!,
      })
    );
  }

  return result;
}

export function getDynamicValidationsForCalculator(
  field: 'principalAmount' | 'numberOfPayments' | 'advanceRate',
  currency: string | null,
  product: LeasingProductDTO | null,
  t: TFunction<'translation'>
): ZodType {
  if (product === null || currency === null) {
    return z.number();
  }
  if (field === 'principalAmount') {
    const arr = product.paramConfigs.filter(
      (v) => v.currency === currency && v.paramType === 'PRINCIPAL_AMOUNT'
    );
    if (arr.length !== 0) {
      return setMinMax(arr, t, [
        'VALIDATION_LEASING_AMOUNT_MORE_THAN_X',
        'VALIDATION_LEASING_AMOUNT_LESS_THAN_X',
      ]);
    }
    return z.number().positive({ message: t('VALIDATION_POSITIVE_AMOUNT')! });
  } else if (field === 'numberOfPayments') {
    const arr = product.paramConfigs.filter(
      (v) => v.currency === currency && v.paramType === 'NUMBER_OF_PAYMENTS'
    );
    if (arr.length !== 0) {
      return setMinMax(arr, t);
    }
    return z.number().positive({ message: t('VALIDATION_POSITIVE_AMOUNT')! });
  } else {
    const arr = product.paramConfigs.filter(
      (v) => v.currency === currency && v.paramType === 'ADVANCE_RATE'
    );
    if (arr.length !== 0) {
      return setMinMax(arr, t);
    }
    return z.number();
  }
}
