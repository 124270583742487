import {useMutation, useQuery} from 'react-query';
import axiosInstance from '../../../utils/axios';
import {AxiosResponse} from 'axios';
import {StatementState} from './store';
import {useBackdropContext} from '../../../context/BackdropProvider';
import {
  CalculatorRequestDTO,
  CalculatorResponseDTO,
  LCSAddClientApprovalsRequest,
  LCSVerificationInitialData,
  MetaDataDTO,
  OtpCreateRequest,
  OtpVerifyRequest,
  StatementDTO,
} from './model';

const BASE_URL_METADATA = '/meta-data';
const BASE_URL_STATEMENTS = '/statements';
const BASE_URL_OTP = '/otp';

const ENDPOINT_METADATA_LOAD = `${BASE_URL_METADATA}/load`;
const ENDPOINT_IP_ADDRESS = `${BASE_URL_METADATA}/get-ip`;

const ENDPOINT_CALCULATE_MONTHLY_PAYMENT = `${BASE_URL_STATEMENTS}/calculate-monthly-payment`;
const ENDPOINT_ADD_STATEMENT = `${BASE_URL_STATEMENTS}/add`;

const ENDPOINT_OTP_INITIAL_DATA = `${BASE_URL_OTP}/initial-data`;
const ENDPOINT_OTP_CREATE = `${BASE_URL_OTP}/create`;
const ENDPOINT_OTP_VERIFY = `${BASE_URL_OTP}/verify`;

const ENDPOINT_CONFIRMATION = `${BASE_URL_OTP}/approvals`;

export function useLoadMetadataQuery() {
  return useQuery<never, never, AxiosResponse<MetaDataDTO>>(
    [ENDPOINT_METADATA_LOAD],
    async () => {
      return axiosInstance.post(ENDPOINT_METADATA_LOAD);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}

export function useVerificationInitialData() {
  return useQuery<never, never, AxiosResponse<LCSVerificationInitialData>>(
    [ENDPOINT_OTP_INITIAL_DATA],
    async () => {
      return axiosInstance.post(ENDPOINT_OTP_INITIAL_DATA);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      useErrorBoundary: false,
      suspense: false,
      onError: () => {
        return undefined;
      },
    }
  );
}

export function useLoadMonthlyPaymentMutation(
  calculatorInfo: CalculatorRequestDTO,
  setCalculatorInfo: (calculatorInfo: StatementState['calculatorInfo']) => void
) {
  return useMutation<
    AxiosResponse<CalculatorResponseDTO>,
    never,
    {
      request: CalculatorRequestDTO;
      productCode: string;
    }
  >(
    [ENDPOINT_CALCULATE_MONTHLY_PAYMENT],
    async (params) => {
      return axiosInstance.post(
        ENDPOINT_CALCULATE_MONTHLY_PAYMENT + `?productCode=${params.productCode}`,
        params.request
      );
    },
    {
      onSuccess: (data) =>
        setCalculatorInfo({
          ...calculatorInfo,
          monthlyPayment: data.data.monthlyPayment,
        }),
    }
  );
}

export function useAddStatementMutation(onSuccess: () => void) {
  const { setBackdropOpen } = useBackdropContext();

  return useMutation<never, never, StatementDTO>(
    [ENDPOINT_ADD_STATEMENT],
    async (params) => {
      if (params.financialParams.monthlyPayment === undefined) {
        params.financialParams.monthlyPayment = 0;
      }
      return axiosInstance.post(ENDPOINT_ADD_STATEMENT, params);
    },
    {
      onMutate: () => setBackdropOpen(true),
      onSuccess: onSuccess,
      onSettled: () => setBackdropOpen(false),
    }
  );
}

export function useCreateOtpForPhoneMutation() {
  return useMutation<AxiosResponse<never>, never, OtpCreateRequest>(
    [ENDPOINT_OTP_CREATE],
    async (params) => {
      return axiosInstance.post(ENDPOINT_OTP_CREATE, params);
    }
  );
}

export function useVerifyOtpForPhoneMutation() {
  return useMutation<AxiosResponse<boolean>, never, OtpVerifyRequest>(
    [ENDPOINT_OTP_VERIFY],
    async (params) => {
      return axiosInstance.post(ENDPOINT_OTP_VERIFY, params);
    },
    {
      retry: 5,
      retryDelay: 1000,
    }
  );
}

const REPORT_TYPES = ['CI_REPORT', 'RS_REPORT', 'SDA_REPORT'];

export function useConfirmationMutation() {
  return useMutation<never, never, Omit<LCSAddClientApprovalsRequest, 'approvalTypes'>>(
    [ENDPOINT_CONFIRMATION],
    async (params) => {
      return axiosInstance.post(ENDPOINT_CONFIRMATION, {
        approvalTypes: REPORT_TYPES,
        ...params,
      });
    }
  );
}

export function useIpAddressQuery() {
  return useQuery<AxiosResponse<string>>([ENDPOINT_IP_ADDRESS], async () => {
    return axiosInstance.get(ENDPOINT_IP_ADDRESS);
  });
}
