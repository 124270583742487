import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {ZButton} from '../../../shared/input/ZButton';
import {Box, Menu, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useCreateOtpForPhoneMutation} from './backend';

type OTPConfirmationProps = {
  phoneVerifyAccepted: boolean;
  isPhoneError: boolean;
  isPhoneVerified: boolean;
  acceptPhoneVerification: () => void;
  phoneNumber: string;
};

export function OTPConfirmation({
  phoneVerifyAccepted,
  isPhoneError,
  acceptPhoneVerification,
  isPhoneVerified,
  phoneNumber,
}: OTPConfirmationProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mutation = useCreateOtpForPhoneMutation();

  return (
    <>
      <ZButton
        disabled={isPhoneVerified || phoneVerifyAccepted || isPhoneError}
        onClick={handleClick}
      >
        <Box px={2}>{t('phoneVerifyButton')}</Box>
      </ZButton>
      <Menu
        id="verify-menu"
        MenuListProps={{
          'aria-labelledby': 'verify-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box width={'30em'} padding={2}>
          <Typography mb={2} fontWeight={'bolder'}>
            {t('acceptVerifyPhoneNumberTitle')}
          </Typography>
          <Typography mb={2}>{t('acceptVerifyPhoneNumberBody')}</Typography>
          <Stack direction={'row'} justifyContent={'center'}>
            <ZButton
              onClick={() => {
                handleClose();
                mutation.mutate(
                  {
                    phoneNumber: phoneNumber,
                  },
                  {
                    onSuccess: () => {
                      acceptPhoneVerification();
                    },
                  }
                );
              }}
            >
              {t('send')}
            </ZButton>
          </Stack>
        </Box>
      </Menu>
    </>
  );
}
