import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Stack, useMediaQuery, useTheme} from '@mui/material';
import {NavigateBefore, NavigateNext} from '@mui/icons-material';
import {getStepContent, isStepValid, STATEMENT_STEPS} from './utils';
import {useStatementStore} from './store';
import {useAddStatementMutation, useConfirmationMutation} from './backend';
import SectionsDivider from '../../../shared/SectionsDivider';
import {QontoConnector, QontoStepIcon} from './stepper/stepper-styles';
import {SectionSuccessWithReview} from './SectionSuccessWithReview';
import {SubjectLegalType} from './model';

const FormPage = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // theme
  const theme = useTheme();
  const higherThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  // store
  const state = useStatementStore();
  const setCalculatorStepSkipped = useStatementStore((s) => s.setCalculatorStepSkipped);
  const statement = useStatementStore((s) => s.statement);

  const confirmationMutation = useConfirmationMutation();
  const addStatementMutation = useAddStatementMutation(handleNext);

  function handleSubmitStatement() {
    if (statement !== null && statement.ipAddress) {
      confirmationMutation.mutate(
        {
          ipAddress: statement?.ipAddress,
          approverName: state.subject.firstname + ' ' + state.subject.lastname,
          approverNationalId:
            state.subject.legalType === SubjectLegalType.LEGAL_ENTITY
              ? state.subject.senderNationalId
              : state.subject.nationalId,
          nationalId: state.subject.nationalId,
        },
        {
          onSuccess: () => {
            addStatementMutation.mutate(statement);
          },
        }
      );
    }
  }

  return (
    <>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Stack alignItems={'center'} direction={'row'} spacing={1}></Stack>
        <SectionsDivider contentText={t('createStatement')} />
        <Box mt={3} />
        <Stepper
          activeStep={activeStep}
          sx={{
            mb: 3,
          }}
          orientation={higherThanSm ? 'horizontal' : 'vertical'}
          connector={higherThanSm ? <QontoConnector /> : undefined}
          alternativeLabel={higherThanSm}
        >
          {STATEMENT_STEPS.map((code) => (
            <Step key={code}>
              <StepLabel StepIconComponent={higherThanSm ? QontoStepIcon : undefined}>
                <Typography variant="subtitle2">{t(code)}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === STATEMENT_STEPS.length ? (
          <SectionSuccessWithReview />
        ) : (
          <React.Fragment>
            {getStepContent(activeStep, handleNext)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }} startIcon={<NavigateBefore />}>
                  {t('goBack')}
                </Button>
              )}
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                    setCalculatorStepSkipped(true);
                  }}
                  sx={{ mt: 3, ml: 1 }}
                  endIcon={<NavigateNext />}
                >
                  {t('skipStep')}
                </Button>
              )}
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  onClick={
                    activeStep === STATEMENT_STEPS.length - 1
                      ? handleSubmitStatement
                      : () => {
                          handleNext();
                          if (activeStep === 1) {
                            setCalculatorStepSkipped(false);
                          }
                        }
                  }
                  sx={{ mt: 3, ml: 1 }}
                  endIcon={<NavigateNext />}
                  disabled={!isStepValid(activeStep, state)}
                >
                  {activeStep === STATEMENT_STEPS.length - 1 ? t('send') : t('next')}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </>
  );
};

export default FormPage;
