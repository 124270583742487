import {Backdrop, CircularProgress} from '@mui/material';
import {useIsMutating} from 'react-query';

export function MutationLoadingOverlay() {
  const isMutating = useIsMutating();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isMutating > 0}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
