import axios from 'axios';
import {parseISO} from 'date-fns';
import {useEffect, useMemo, useState} from 'react';

function getBaseUrl() {
  const url = import.meta.env.VITE_SERVER_URL as string;
  if (url) {
    return url;
  }

  return 'http://localhost:8383';
}

const axiosInstance = axios.create({
  baseURL: `${getBaseUrl()}/api`,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  function (response) {
    handleDates(response.data);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: any): boolean => {
  return value && typeof value === 'string' && isoDateFormat.test(value);
};

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }
};

export function useIPAddress() {
  //creating IP state
  const [ip, setIP] = useState<string | null>(null);

  //creating function to load ip address from the API
  const getData = async () => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res) => {
        setIP(res.data.IPv4);
      })
      .catch((v) => {
        axios.get('https://geolocation-db.com/json/').then((res) => {
          setIP(res.data.IPv4);
        });
      });
    // setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  return useMemo(() => ip, [ip]);
}

export default axiosInstance;
