import {CardContent, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {CheckCircle, Warning} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {SubjectLegalType} from './model';
import Card from '@mui/material/Card';
import {useStatementStore} from './store';

export function SectionSuccessWithReview() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { subject } = useStatementStore();

  return (
    <>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={3}
        my={5}
      >
        <Typography variant="h5" gutterBottom>
          <CheckCircle
            color={'success'}
            fontSize={'large'}
            sx={{
              mr: 2,
            }}
          />
          {t('orderSuccessful')}
        </Typography>
        <Typography variant={'subtitle1'}>{t('ourRepresentativeWillContactYouShortly')}</Typography>
        {import.meta.env.VITE_STATEMENT_SUCCESS_SLOGAN_CODE !== undefined && (
          <Typography variant={'subtitle1'} color={(t) => t.palette.text.secondary}>
            {t(import.meta.env.VITE_STATEMENT_SUCCESS_SLOGAN_CODE)}
          </Typography>
        )}
      </Stack>
      <Card variant={'outlined'} sx={{ mt: 2 }} key={'creditInfoCheck'}>
        <CardContent>
          {subject.legalType === SubjectLegalType.INDIVIDUAL && (
            <Stack direction={'row'} alignItems={'center'} pt={2}>
              <Warning color={'warning'} sx={{ m: 2 }} />
              <span>
                {t('creditInfoWarningMessage') + ' '}
                <span
                  style={{
                    color: theme.palette.secondary.dark,
                    marginLeft: 5,
                    fontWeight: 'bold',
                    fontSize: 'medium',
                  }}
                >
                  TBC - GE40TB7011636020100026
                </span>
              </span>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
}
