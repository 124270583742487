import { Divider, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { HomeButton } from './HomeButton';
import React from 'react';

type SectionsDividerProps = {
  contentText: string;
  marginTop?: string | number;
  marginBottom?: string | number;
  paddingLeft?: string;
  dividerWrapperPadding?: string;
  fontWeight?: number;
  fontSize?: string;
};

const SectionsDivider = ({
  contentText,
  marginTop,
  marginBottom,
  paddingLeft,
  dividerWrapperPadding,
  fontWeight,
  fontSize,
}: SectionsDividerProps) => {
  const theme = useTheme();

  return (
    <Divider
      sx={{
        '&.MuiDivider-root': {
          '&::before': {
            width: '0%',
          },
        },
        // '& .MuiDivider-wrapper': {
        //   paddingLeft: dividerWrapperPadding ?? 'calc(8px * 1.2)',
        // },
        display: 'flex',
        flexDirection: 'row',
      }}
      textAlign={'left'}
    >
      <Typography
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        color={theme.palette.text.secondary}
        fontSize={fontSize || '1.08rem'}
        fontWeight={fontWeight || 600}
      >
        <HomeButton />
        {contentText}
      </Typography>
    </Divider>
  );
};

export default SectionsDivider;
