import { ThemeProvider } from '@mui/material';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { getTheme } from '../utils/theme';

type CustomThemeProviderProps = {
  children: ReactNode | ReactNode[];
};

const DarkModeContext = createContext<{
  darkMode: boolean;
  toggleDarkMode: () => void;
}>({
  darkMode: false,
  toggleDarkMode: () => {},
});

export const useDarkModeContext = () => {
  const context = useContext(DarkModeContext);

  if (!context) {
    throw new Error('You forgot to wrap your component with DarkModeProvider');
  }

  return context;
};

export function CustomThemeProvider({ children }: CustomThemeProviderProps) {
  const [darkMode, setDarkMode] = useState(false);

  const value = useMemo(
    () => ({
      darkMode,
      toggleDarkMode: () => {
        setDarkMode((prevState) => !prevState);
      },
    }),
    [darkMode, setDarkMode]
  );

  return (
    <DarkModeContext.Provider value={value}>
      <ThemeProvider theme={getTheme(darkMode ? 'dark' : 'light')}>{children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
}
