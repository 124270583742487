import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

export interface MenuItemType {
  name: string;
  value: any;
}

export interface ZSelectFieldProps extends SelectProps {
  id: string;
  menuItems: MenuItemType[];
}

export function ZSelectField({ id, label, menuItems, ...rest }: ZSelectFieldProps) {
  return (
    <FormControl fullWidth size={'small'} color={'secondary'}>
      <InputLabel id={id + '-label'}>{label}</InputLabel>
      <Select
        labelId={id + '-label'}
        id={id}
        label={label}
        {...rest}
        color={'secondary'}
        onClose={() => {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.activeElement.blur();
          }, 0);
        }}
      >
        {menuItems.map((v) => {
          return (
            <MenuItem value={v.value} key={v.value}>
              {v.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
