import Box from '@mui/material/Box';
import {Dispatch, SetStateAction, useMemo, useRef, useState} from 'react';
import {useCreateOtpForPhoneMutation, useVerifyOtpForPhoneMutation,} from './backend';
import {ButtonGroup, CircularProgress, Stack, Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CheckCircle, Error, RestartAlt} from '@mui/icons-material';
import AuthCode, {AuthCodeRef} from 'react-auth-code-input';
import {UseMutationResult} from 'react-query';
import {useStatementStore} from './store';
import {AxiosResponse} from 'axios';
import {OtpVerifyRequest} from './model';
import {Countdown} from '../../components/Countdown';
import {ZButton} from '../../../shared/input/ZButton';

type PhoneVerificationSectionProps = {
  isSuccess: boolean;
  onSuccess: () => void;
  phoneNumber: string;
  setPhoneVerifyAccepted: Dispatch<SetStateAction<boolean>>;
};

const DEFAULT_CODE_LENGTH = 4;

export function PhoneVerification({
                                    isSuccess,
                                    onSuccess,
                                    phoneNumber,
                                    setPhoneVerifyAccepted,
                                  }: PhoneVerificationSectionProps) {
  const ref = useRef<AuthCodeRef>(null);
  const [otpActive, setOtpActive] = useState(true);

  const addVerifiedPhoneNumber = useStatementStore((state) => state.addVerifiedPhoneNumber);
  // const {data} = useVerificationInitialData();

  const createMutation = useCreateOtpForPhoneMutation();
  const mutation = useVerifyOtpForPhoneMutation();

  // const CODE_LENGTH = data?.data.codeLength ?? DEFAULT_CODE_LENGTH;
  const CODE_LENGTH = DEFAULT_CODE_LENGTH;

  return (
    <Stack flexDirection={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
      <Stack flexDirection={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
        <AuthCode
          ref={ref}
          autoFocus={true}
          length={CODE_LENGTH}
          disabled={isSuccess || mutation.isLoading || !otpActive}
          containerClassName="auth_container"
          inputClassName="auth_input"
          onChange={(res) => {
            if (res.length === CODE_LENGTH) {
              mutation.mutate(
                {
                  phoneNumber: phoneNumber,
                  code: res,
                },
                {
                  onSuccess: (data) => {
                    if (data.data) {
                      addVerifiedPhoneNumber(phoneNumber);
                      onSuccess();
                    }
                  },
                  onError: () => {
                    ref.current?.clear();
                  },
                }
              );
            }
          }}
        />
      </Stack>

      <VerificationStateHint
        mutation={mutation}
        setOtpActive={setOtpActive}
        onResendOtp={() => {
          setPhoneVerifyAccepted(false);
          setTimeout(() => {
            createMutation.mutate(
              {
                phoneNumber: phoneNumber,
              },
              {
                onSuccess: () => {
                  setPhoneVerifyAccepted(true);
                },
              }
            );
          }, 0);
        }}
      />
    </Stack>
  );
}

function VerificationStateHint({
                                 mutation,
                                 setOtpActive,
                                 onResendOtp,
                               }: {
  mutation: UseMutationResult<AxiosResponse<boolean>, never, OtpVerifyRequest>;
  setOtpActive: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onResendOtp: () => void;
}) {
  const {t} = useTranslation();

  // const {data} = useVerificationInitialData();

  const countdown = useMemo(() => {
    return (
      <Countdown
        // initialSeconds={data?.data.countdownInSeconds ?? 120}
        initialSeconds={120}
        onZero={() => setOtpActive(false)}
      />
    );
  }, [setOtpActive]);

  if (mutation.isSuccess && mutation.data.data) {
    return (
      <Stack direction={'row'} spacing={2}>
        <CheckCircle color={'success'}/>
        <Box>{t('phoneVerificationSuccessful')}</Box>
      </Stack>
    );
  } else if (mutation.isSuccess && !mutation.data?.data || (mutation.isError)) {
    return (
      <Stack direction={'row'} spacing={2}>
        <Error color={'error'}/>
        {t('phoneVerificationErrorTryAgain')}

        <Stack
          flexDirection={'row'}
          spacing={1}
          justifyContent={'center'}
          alignItems={'center'}
          mt={2}
        >
          <Tooltip title={t('resendOtp')}>
            <ButtonGroup onClick={onResendOtp} color={'error'}>
              <ZButton>{countdown}</ZButton>
              <ZButton>
                <RestartAlt/>
              </ZButton>
            </ButtonGroup>
          </Tooltip>
        </Stack>
      </Stack>
    );
  } else if (mutation.isLoading) {
    return <CircularProgress/>;
  } else {
    return (
      <Box textAlign={'center'}>
        {t('enterReceivedText')}

        <Stack
          flexDirection={'row'}
          spacing={1}
          justifyContent={'center'}
          alignItems={'center'}
          mt={2}
        >
          <Tooltip title={t('resendOtp')}>
            <ButtonGroup onClick={onResendOtp}>
              <ZButton>{countdown}</ZButton>
              <ZButton>
                <RestartAlt/>
              </ZButton>
            </ButtonGroup>
          </Tooltip>
        </Stack>
      </Box>
    );
  }
}
