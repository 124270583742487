import {useEffect, useState} from 'react';

export const Countdown = ({
  initialSeconds,
  onZero,
}: {
  initialSeconds: number;
  onZero: () => void;
}) => {
  const [totalSeconds, setTotalSeconds] = useState(initialSeconds);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  useEffect(() => {
    if (totalSeconds > 0) {
      const interval = setInterval(() => {
        setTotalSeconds(totalSeconds - 1);
        if (totalSeconds <= 1) {
          onZero();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [onZero, totalSeconds]);

  return (
    <div>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </div>
  );
};
