import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout';
import FormPage from 'main/pages/statement';

const Content = () => {
  return (
    <Routes>
      <Route path={'/'} element={<MainLayout />}>
        <Route index element={<FormPage />} />
      </Route>
    </Routes>
  );
};

export default Content;
