import Content from './main/components/Content';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './utils/query-client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { I18nextProvider } from 'react-i18next';
import i18_frontend from './messages/i18_frontend';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { CustomThemeProvider } from './context/CustomThemeProvider';
import { BackdropProvider } from './context/BackdropProvider';

function App() {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18_frontend}>
        <QueryClientProvider client={queryClient}>
          <CustomThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BackdropProvider>
                <CssBaseline />
                <Content />
              </BackdropProvider>
            </LocalizationProvider>
          </CustomThemeProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
