import { IconButton, Tooltip } from '@mui/material';
import { HomeRounded } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function HomeButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate('/');
    navigate(0);
  };

  return (
    <Tooltip title={t('returnHome')}>
      <IconButton color={'primary'} onClick={refreshPage} sx={{ mr: 1 }}>
        <HomeRounded fontSize={'medium'} />
      </IconButton>
    </Tooltip>
  );
}
