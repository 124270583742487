import { AppBar, Box, IconButton, Toolbar, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DarkMode, LightMode } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Image } from 'mui-image';
import lightHeader from '../../assets/crystal/header_light.png';
import darkHeader from '../../assets/crystal/header_dark.png';
import { useDarkModeContext } from '../../context/CustomThemeProvider';
import { useMemo } from 'react';

export function AppHeader() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { darkMode, toggleDarkMode } = useDarkModeContext();

  const imageContainer = useMemo(
    () => (
      <Box width={darkMode ? '12rem' : '11rem'}>
        <Image
          src={darkMode ? lightHeader : darkHeader}
          height="100%"
          width="100%"
          fit="cover"
          duration={1000}
          showLoading={true}
          errorIcon={true}
          shift={null}
          distance="100px"
          shiftDuration={900}
          bgColor="inherit"
        />
      </Box>
    ),
    [darkMode]
  );

  return (
    <AppBar
      position="absolute"
      color="inherit"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.divider}`,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
          flexGrow: 1,
          maxWidth: 'md',
          height: '5rem',
          alignItems: 'center',
        }}
      >
        {imageContainer}
        <Box flexGrow={1} />
        <Tooltip title={t('changeTheme')}>
          <IconButton onClick={toggleDarkMode} color={'inherit'}>
            {darkMode ? <DarkMode /> : <LightMode color={'primary'} />}
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}
