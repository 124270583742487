import { TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ZTextField } from '../ZTextField';

export interface FormInput {
  name: string;
  control: Control<any>;
}

export function FormTextField({ name, control, ...rest }: TextFieldProps & FormInput) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <ZTextField
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : undefined}
            {...rest}
          />
        );
      }}
    />
  );
}
