import {Outlet} from 'react-router';
import Container from '@mui/material/Container';
import {AppHeader} from '../AppHeader';
import {LoadingTopBar} from './LoadingTopBar';
import {ErrorBoundary} from 'react-error-boundary';
import {DefaultErrorFallback} from './DefaultErrorFallback';
import {QueryErrorResetBoundary} from 'react-query';
import {MutationLoadingOverlay} from './MutationLoadingOverlay';

export function MainLayout() {
  return (
    <>
      <LoadingTopBar />
      <MutationLoadingOverlay/>
      <AppHeader />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary FallbackComponent={DefaultErrorFallback} onReset={reset}>
              <Outlet />
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Container>
    </>
  );
}
