import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      cacheTime: 0, // don't cache by default
      staleTime: 0, // stale immediately by default
      retry: false,
      suspense: false,
      keepPreviousData: true,
      useErrorBoundary: true,
    },
    mutations: {
      retry: false,
      useErrorBoundary: true,
    },
  },
});
