import {Checkbox, CheckboxProps, FormControlLabel} from '@mui/material';

export type ZCheckboxProps = {
  label?: string;
};

export function ZCheckbox({ label, ...rest }: CheckboxProps & ZCheckboxProps) {
  return (
    <FormControlLabel
      control={<Checkbox {...rest} checked={Boolean(rest.value)} />}
      label={label}
    />
  );
}
