import i18next from 'i18next';
import ka from './ka.json';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'ka',
  resources: {
    ka: {
      common: ka,
      translation: ka,
    },
  },
});

export default i18next;
