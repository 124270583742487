import { Box, LinearProgress } from '@mui/material';
import { useIsFetching } from 'react-query';

export function LoadingTopBar() {
  const isFetching = useIsFetching();

  return isFetching > 0 ? (
    <Box left={0} top={0} width={'100%'} height={'20px'} position={'fixed'} zIndex={9999}>
      <LinearProgress />
    </Box>
  ) : (
    <></>
  );
}
