import { createTheme, PaletteMode } from '@mui/material';

type ColorPalette = {
  primary: string;
  secondary: string;
  success: string;
  warning: string;
  disabled: string;
};

function getColorPalette(): ColorPalette {
  return {
    primary: import.meta.env.VITE_PRIMARY_COLOR,
    secondary: import.meta.env.VITE_SECONDARY_COLOR,
    success: import.meta.env.VITE_SUCCESS_COLOR,
    warning: import.meta.env.VITE_WARNING_COLOR,
    disabled: import.meta.env.VITE_DISABLED_COLOR,
  };
}

export function getTheme(mode: PaletteMode) {
  const palette = getColorPalette();

  return createTheme({
    shape: {
      borderRadius: 16,
    },
    palette: {
      mode: mode,
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.secondary,
      },
      success: {
        main: palette.success,
      },
      warning: {
        main: palette.warning,
      },
      action: {
        disabled: palette.disabled,
      },
    },
    typography: {
      fontSize: 12,
      fontFamily: [
        import.meta.env.VITE_FONT_FAMILY,
        '-apple-system',
        'Roboto',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'gotham',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });
}
