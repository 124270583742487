import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardActions } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Image } from 'mui-image';
import car from '../../../assets/crystal/projectcards/car.jpg';
import farm from '../../../assets/crystal/projectcards/farm.jpg';
import construction from '../../../assets/crystal/projectcards/construction.jpg';
import financeA from '../../../assets/crystal/projectcards/leasing-finance-2.jpg';
import autoA from '../../../assets/crystal/projectcards/leasing-auto-1.jpg';
import expressA from '../../../assets/crystal/projectcards/leasing-exspress-1.jpg';
import reverse from '../../../assets/crystal/projectcards/reverse-leasing.jpg';

type ProjectCardProps = {
  name: string;
  code: string;
  selected: boolean;
  description?: string;
  onClick?: () => void;
};

const FILENAMES = [
  { name: 'FARM', src: farm },
  { name: 'CONSTRUCTION', src: construction },
  { name: 'CAR', src: car },
  {
    name: 'AUTO',
    src: autoA,
  },
  { name: 'EXSPRESS', src: expressA },
  { name: 'FINANCE', src: financeA },
  { name: 'REVERSE', src: reverse },
];

function getRelevantFile(name: string) {
  const baseline = name.toUpperCase();
  for (const file of FILENAMES) {
    if (baseline.includes(file.name)) {
      return file.src;
    }
  }
  return 'ERR';
}

export function ProjectCard({ name, code, description, selected, onClick }: ProjectCardProps) {
  const content = useMemo(
    () => (
      <>
        <CardMedia>
          <Image
            src={getRelevantFile(code)}
            height={'10rem'}
            fit="cover"
            duration={1500}
            showLoading={true}
            errorIcon={true}
            shift={null}
            distance="100px"
            shiftDuration={900}
            bgColor="inherit"
            alt={name}
          />
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Box flexGrow={1} />
          {selected && !!onClick && (
            <CheckCircle fontSize={'large'} color={'success'} sx={{ pr: 1 }} />
          )}
        </CardActions>
      </>
    ),
    [code, name, description, selected, onClick]
  );

  if (onClick) {
    return (
      <Card
        variant={'outlined'}
        sx={(theme) => ({
          // borderColor: 'red',
          // borderColor: theme.palette.primary.main,
          transition: 'border-color 0.3s ease, border-width 0.3s ease',
          ':hover': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          },
        })}
      >
        <CardActionArea onClick={onClick}>{content}</CardActionArea>
      </Card>
    );
  }

  return <Card variant={'outlined'}>{content}</Card>;
}
