import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {InputAdornment, Stack} from '@mui/material';
import {AlternateEmail, Badge} from '@mui/icons-material';
import {SubjectDTO, SubjectLegalType} from './model';
import {useStatementStore} from './store';
import {FormTextField} from '../../../shared/input/form/FormTextField';
import {FormSelectField} from '../../../shared/input/form/FormSelectField';
import React, {useEffect, useMemo, useState} from 'react';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {TFunction} from 'i18next';
import {EMAIL_REGEX, LETTER_ONLY_REGEX, NUMBER_ONLY_REGEX} from 'utils/validation';
import {useDebounce, useUpdateEffect} from 'usehooks-ts';
import {PhoneVerification} from './PhoneVerification';
import {OTPConfirmation} from './OTPConfirmation';

const LEGAL_SCHEMA = (t: TFunction<'translation'>) =>
  z.object({
    name: z.string().min(1, { message: t('VALIDATION_NON_EMPTY_STRING')! }),
    firstname: z
      .string()
      .min(1, {
        message: t('VALIDATION_REQUIRED_FIELD')!,
      })
      .regex(LETTER_ONLY_REGEX, { message: t('VALIDATION_NO_NUMBERS')! }),
    lastname: z
      .string()
      .min(1, {
        message: t('VALIDATION_REQUIRED_FIELD')!,
      })
      .regex(LETTER_ONLY_REGEX, { message: t('VALIDATION_NO_NUMBERS')! }),
    nationalId: z
      .string()
      .length(9, { message: t('VALIDATION_LEGAL_ID')! })
      .regex(NUMBER_ONLY_REGEX, { message: t('VALIDATION_ONLY_NUMBERS')! }),
    senderNationalId: z
      .string()
      .length(11, { message: t('VALIDATION_PERSONAL_ID')! })
      .regex(NUMBER_ONLY_REGEX, { message: t('VALIDATION_ONLY_NUMBERS')! }),
    phone: z
      .string()
      .length(9, { message: t('VALIDATION_PHONE_NUMBER')! })
      .regex(NUMBER_ONLY_REGEX, { message: t('VALIDATION_ONLY_NUMBERS')! }),
    email: z
      .string()
      .optional()
      .refine((value) => value?.trim() === '' || value?.match(EMAIL_REGEX), {
        message: t('VALIDATION_EMAIL')!,
      })
      .optional(),
  });

const INDIVIDUAL_SCHEMA = (t: TFunction) =>
  z.object({
    firstname: z
      .string()
      .min(1, {
        message: t('VALIDATION_REQUIRED_FIELD')!,
      })
      .regex(LETTER_ONLY_REGEX, { message: t('VALIDATION_NO_NUMBERS')! }),
    lastname: z
      .string()
      .min(1, {
        message: t('VALIDATION_REQUIRED_FIELD')!,
      })
      .regex(LETTER_ONLY_REGEX, { message: t('VALIDATION_NO_NUMBERS')! }),
    nationalId: z
      .string()
      .length(11, { message: t('VALIDATION_PERSONAL_ID')! })
      .regex(NUMBER_ONLY_REGEX, { message: t('VALIDATION_ONLY_NUMBERS')! }),
    phone: z
      .string()
      .length(9, { message: t('VALIDATION_PHONE_NUMBER')! })
      .regex(NUMBER_ONLY_REGEX, { message: t('VALIDATION_ONLY_NUMBERS')! }),
    email: z
      .string()
      .optional()
      .refine((value) => value?.trim() === '' || value?.match(EMAIL_REGEX), {
        message: t('VALIDATION_EMAIL')!,
      })
      .optional(),
  });

export function SectionPersonalInfo() {
  const { t } = useTranslation();

  // start background fetching on this page load
  // useVerificationInitialData();

  const subject = useStatementStore((state) => state.subject);
  const setSubject = useStatementStore((state) => state.setSubject);
  const subjectStepValid = useStatementStore((state) => state.subjectStepValid);
  const setSubjectStepValidity = useStatementStore((state) => state.setSubjectStepValidity);
  const verifiedPhoneNumbers = useStatementStore((state) => state.verifiedPhoneNumbers);

  const schema = useMemo(() => {
    return subject.legalType === SubjectLegalType.LEGAL_ENTITY ? LEGAL_SCHEMA : INDIVIDUAL_SCHEMA;
  }, [subject.legalType]);

  const { control, watch, formState, trigger } = useForm({
    resolver: zodResolver(schema(t)),
    defaultValues: subject,
    mode: 'all',
  });

  const isLegalEntity = watch('legalType') === SubjectLegalType.LEGAL_ENTITY;
  const phoneNumber = watch('phone');

  const [phoneVerifyAccepted, setPhoneVerifyAccepted] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(
    verifiedPhoneNumbers.includes(phoneNumber)
  );
  const isPhoneError = useDebounce(
    formState.errors.hasOwnProperty('phone') || phoneNumber.length === 0,
    200
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setSubject(value as SubjectDTO);
    });

    return subscription.unsubscribe;
  }, [setSubject, watch]);

  useEffect(() => {
    setPhoneVerifyAccepted(false);
    if (verifiedPhoneNumbers.includes(phoneNumber)) {
      setIsPhoneVerified(true);
    } else {
      setIsPhoneVerified(false);
    }
  }, [phoneNumber, verifiedPhoneNumbers]);

  useUpdateEffect(() => {
    trigger();
  }, [isLegalEntity]);

  useEffect(() => {
    if (subjectStepValid === false) {
      trigger();
    }
  }, []);

  useEffect(() => {
    setSubjectStepValidity(formState.isValid && isPhoneVerified);
  }, [formState.isValid, setSubjectStepValidity, isPhoneVerified]);

  return (
    <>
      <form>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xs={12}>
            <FormSelectField
              id={'legalType'}
              name={'legalType'}
              label={t('legalType')}
              required
              menuItems={Object.values(SubjectLegalType).map((v) => ({
                name: t(v),
                value: v,
              }))}
              fullWidth
              control={control}
            />
          </Grid>
          {isLegalEntity && (
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} sx={{ pl: 1 }}>
                {t('companyInfo')}
              </Typography>
            </Grid>
          )}
          {isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                id={'companyName'}
                name={'name'}
                label={t('companyName')}
                control={control}
                autoComplete={'name'}
                fullWidth
                required
              />
            </Grid>
          )}
          {isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                id={'companyId'}
                name={'nationalId'}
                label={t('companyId')}
                control={control}
                autoComplete={'companyId'}
                fullWidth
                required
              />
            </Grid>
          )}
          {isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                id={'email'}
                name="email"
                label={t('email')}
                control={control}
                fullWidth
                autoComplete="email"
                type={'email'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AlternateEmail fontSize={'small'} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                required
                control={control}
                color={isPhoneVerified ? 'success' : undefined}
                focused={isPhoneVerified}
                id={'phone'}
                name={'phone'}
                autoComplete="phone"
                label={t('phone')}
                type={'tel'}
                InputProps={{
                  endAdornment: (
                    <OTPConfirmation
                      isPhoneError={isPhoneError}
                      phoneVerifyAccepted={phoneVerifyAccepted}
                      isPhoneVerified={isPhoneVerified}
                      acceptPhoneVerification={() => setPhoneVerifyAccepted(true)}
                      phoneNumber={phoneNumber}
                    />
                  ),
                }}
              />
            </Grid>
          )}
          {isLegalEntity && (
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} sx={{ pl: 1 }}>
                {t('representatorInfo')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormTextField
              id={'firstname'}
              name={'firstname'}
              label={t('firstname')}
              control={control}
              autoComplete={'firstname'}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              id={'lastname'}
              name={'lastname'}
              control={control}
              required
              label={t('lastname')}
              fullWidth
              autoComplete="lastname"
            />
          </Grid>
          <Grid item xs={12}>
            {!isLegalEntity ? (
              <FormTextField
                required
                fullWidth
                control={control}
                id={'nationalId'}
                name={'nationalId'}
                label={t('nationalId')}
                autoComplete="senderNationalId"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Badge fontSize={'small'} />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <FormTextField
                required
                fullWidth
                control={control}
                id={'senderNationalId'}
                name={'senderNationalId'}
                label={t('nationalId')}
                autoComplete="senderNationalId"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Badge fontSize={'small'} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
          {!isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                id={'email'}
                name="email"
                label={t('email')}
                control={control}
                fullWidth
                autoComplete="email"
                type={'email'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AlternateEmail fontSize={'small'} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!isLegalEntity && (
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                required
                control={control}
                id={'phone'}
                name={'phone'}
                autoComplete="phone"
                color={isPhoneVerified ? 'success' : undefined}
                label={t('phone')}
                type={'tel'}
                focused={isPhoneVerified}
                InputProps={{
                  endAdornment: (
                    <OTPConfirmation
                      isPhoneError={isPhoneError}
                      phoneVerifyAccepted={phoneVerifyAccepted}
                      isPhoneVerified={isPhoneVerified}
                      acceptPhoneVerification={() => setPhoneVerifyAccepted(true)}
                      phoneNumber={phoneNumber}
                    />
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>
      </form>
      {phoneVerifyAccepted && (
        <Stack direction={'row'} justifyContent={'center'} mt={2}>
          <PhoneVerification
            isSuccess={isPhoneVerified}
            onSuccess={() => setIsPhoneVerified(true)}
            setPhoneVerifyAccepted={setPhoneVerifyAccepted}
            phoneNumber={phoneNumber}
          />
        </Stack>
      )}
    </>
  );
}
