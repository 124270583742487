import { Backdrop, CircularProgress } from '@mui/material';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

const BackdropContext = createContext<{
  setBackdropOpen: (state: boolean) => void;
}>({
  setBackdropOpen: () => {
    return;
  },
});

export const useBackdropContext = () => {
  const context = useContext(BackdropContext);

  if (!context) {
    throw new Error('You forgot to wrap your component with BackdropProvider');
  }

  return context;
};

type BackdropProviderProps = {
  children: ReactNode | ReactNode[];
};

export const BackdropProvider = ({ children }: BackdropProviderProps) => {
  const [backdropOpen, setBackdropOpen] = useState(false);

  const value = useMemo(
    () => ({
      setBackdropOpen,
    }),
    []
  );

  return (
    <>
      <BackdropContext.Provider value={value}>{children}</BackdropContext.Provider>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 99999999,
        }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
