import {Avatar, CardHeader, Divider, List, Stack, Tooltip, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {ProjectCard} from './ProjectCard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useStatementStore} from './store';
import {SubjectLegalType} from './model';
import {Warning} from '@mui/icons-material';
import {formatAmount2, getCurrencySymbol} from '../../../utils/financial-utils';
import {ChangeEvent, useEffect} from 'react';
import {ZTextField} from '../../../shared/input/ZTextField';
import {useIpAddressQuery} from './backend';

type SectionReviewProps = {
  editMode?: boolean;
};

export function SectionReview({editMode = true}: SectionReviewProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  const {
    selectedProduct,
    subject,
    calculatorInfo,
    statement,
    setStatement,
    calculatorStepSkipped,
    comment,
    setComment,
  } = useStatementStore();

  const {data} = useIpAddressQuery();
  const ipAddress = data?.data;

  useEffect(() => {
    if (selectedProduct) {
      setStatement({
        productCode: selectedProduct?.code ?? '',
        clientParams: subject,
        financialParams: calculatorInfo,
        ipAddress: ipAddress ?? '',
        comment: comment,
      });
    }
  }, [calculatorInfo, comment, ipAddress, selectedProduct, setStatement, subject]);

  function handleCommentChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setComment(event.target.value);
  }

  const currencySymbol = getCurrencySymbol(calculatorInfo.currency ?? '');

  return (
    <>
      <List disablePadding>
        <ProjectCard
          name={selectedProduct?.name ?? ''}
          code={selectedProduct?.code ?? ''}
          selected={true}
        />
        <Card variant={'outlined'} sx={{mt: 2}} key={'personalInfo'}>
          <CardHeader
            avatar={
              <Avatar sx={{backgroundColor: theme.palette.secondary.main}}>
                {subject.legalType === SubjectLegalType.LEGAL_ENTITY
                  ? subject.name[0]
                  : subject.firstname[0]}
              </Avatar>
            }
            title={
              <>
                {subject.legalType === SubjectLegalType.LEGAL_ENTITY
                  ? `${subject.name} | (${t('representator')}: ${subject.firstname} ${
                    subject.lastname
                  })`
                  : `${subject.firstname} ${subject.lastname}`}
              </>
            }
            subheader={t(subject.legalType)}
          />
          <CardContent>
            <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-1 gap-x-4'}>
              {subject.legalType === SubjectLegalType.LEGAL_ENTITY && (
                <>
                  <div key={'companyId'}>
                    <Typography
                      style={{
                        color: theme.palette.text.secondary,
                        paddingRight: 18,
                        wordWrap: 'normal',
                      }}
                    >{`${t('companyId')}`}</Typography>
                    {subject.nationalId}
                  </div>
                  <div key={'id'}>
                    <Typography
                      style={{
                        color: theme.palette.text.secondary,
                        paddingRight: 18,
                        wordWrap: 'normal',
                      }}
                    >{`${t('nationalId')}`}</Typography>
                    {subject.senderNationalId}
                  </div>
                </>
              )}

              {subject.legalType === SubjectLegalType.INDIVIDUAL && (
                <div key={'id'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                      wordWrap: 'normal',
                    }}
                  >{`${t('nationalId')}`}</Typography>
                  {subject.nationalId}
                </div>
              )}
              <div key={'phone'}>
                <Typography
                  style={{
                    color: theme.palette.text.secondary,
                    paddingRight: 18,
                  }}
                >{`${t('phone')}`}</Typography>
                {subject.phone}
              </div>
              {subject.email && (
                <div key={'email'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                    }}
                  >{`${t('email')}`}</Typography>
                  {subject.email}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        {!calculatorStepSkipped && (
          <Card variant={'outlined'} sx={{mt: 2}}>
            <CardContent>
              <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-1 gap-x-4'}>
                <div key={'amount'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                    }}
                  >{`${t('assetsValueShort')}`}</Typography>
                  {`${formatAmount2(calculatorInfo.assetsValue)} ${currencySymbol}`}
                </div>
                <div key={'principalAmount'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                    }}
                  >{`${t('remainingPrincipal')}`}</Typography>
                  {`${formatAmount2(calculatorInfo.principalAmount)} ${currencySymbol}`}
                </div>
                {calculatorInfo.issuanceFee > 0 && (
                  <div key={'issuanceFee'}>
                    <Typography
                      style={{
                        color: theme.palette.text.secondary,
                        paddingRight: 18,
                      }}
                    >{`${t('issuanceFee')}`}</Typography>
                    {`${formatAmount2(calculatorInfo.issuanceFee)} ${currencySymbol}`}
                  </div>
                )}
                <div key={'guarantorPercentage'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                    }}
                  >{`${t('advance')}`}</Typography>
                  {formatAmount2(calculatorInfo.advanceRate)}%
                </div>
                <div key={'months'}>
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      paddingRight: 18,
                    }}
                  >{`${t('numberOfPaymentsShort')}`}</Typography>
                  {calculatorInfo.numberOfPayments + ' ' + t('month')}
                </div>
              </div>
              <Divider sx={{my: 1, py: 1}}/>
              <div key={'monthlyFee'} className={'flex justify-between'}>
                <span
                  key={'const'}
                  style={{
                    color: theme.palette.text.secondary,
                    paddingRight: 5,
                  }}
                >{`${t('monthlyPayment')}`}</span>
                <span
                  key={'var'}
                  style={{
                    fontSize: 'x-large',
                  }}
                >
                  {`${formatAmount2(calculatorInfo.monthlyPayment ?? 0)} ${currencySymbol}`}
                </span>
              </div>

              <Stack
                fontSize={'small'}
                mt={2}
                textAlign={'end'}
                color={(t) => t.palette.text.secondary}
              >
                {t('completeForDetails')}
              </Stack>
            </CardContent>
          </Card>
        )}
        <Card variant={'outlined'} sx={{mt: 2}} key={'creditInfoCheck'}>
          <CardContent>
            {editMode ? (
              <Tooltip
                title={<Typography variant={'subtitle2'}>{t('commentHelperTooltip')}</Typography>}
              >
                <ZTextField
                  id={'comment'}
                  label={t('comment')}
                  rows={3}
                  value={statement?.comment}
                  fullWidth={true}
                  multiline={true}
                  onChange={handleCommentChange}
                />
              </Tooltip>
            ) : (
              comment && <Typography>{`${t('comment')}: ${comment}`}</Typography>
            )}
            {subject.legalType === SubjectLegalType.INDIVIDUAL && (
              <Stack direction={'row'} alignItems={'center'} pt={2}>
                <Warning color={'warning'} sx={{m: 2}}/>
                <span>
                  {t('creditInfoWarningMessage') + ' '}
                  <span
                    style={{
                      color: theme.palette.secondary.dark,
                      marginLeft: 5,
                      fontWeight: 'bolder',
                      fontSize: 'medium',
                    }}
                  >
                    TBC - GE40TB7011636020100026
                  </span>
                </span>
              </Stack>
            )}
          </CardContent>
        </Card>
      </List>
    </>
  );
}
