import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';

export function ZButton(props: ButtonProps) {
  return (
    <Button variant={'contained'} size={'small'} {...props}>
      {props.children}
    </Button>
  );
}
