import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Error, ErrorOutlined, Replay } from '@mui/icons-material';
import Typography from '@mui/material/Typography';

export type ErrorFallbackProps = {
  error?: Error;
  resetErrorBoundary: (...args: unknown[]) => void;
};

export function DefaultErrorFallback({ resetErrorBoundary }: ErrorFallbackProps) {
  const { t } = useTranslation();
  return (
    <Box
      role="alert"
      display={'flex'}
      padding={2}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={3}
    >
      <Chip
        label={<Typography variant={'h5'}>{t('errorOccured')}</Typography>}
        variant={'filled'}
        color={'error'}
        icon={<ErrorOutlined color={'error'} />}
      />
      <Tooltip title={t('retry')}>
        <IconButton onClick={resetErrorBoundary}>
          <Replay fontSize={'large'} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
