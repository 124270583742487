import {create} from 'zustand';
import {CalculatorRequestDTO, LeasingProductDTO, StatementDTO, SubjectDTO, SubjectLegalType,} from './model';

export interface StatementState {
  selectedProduct: LeasingProductDTO | null;
  subject: SubjectDTO;
  calculatorInfo: CalculatorRequestDTO;
  statement: StatementDTO | null;
  subjectStepValid?: boolean;
  calculatorStepValid: boolean;
  confirmationStepValid: boolean;
  creditInfoAccepted: boolean;
  calculatorStepSkipped: boolean;
  comment: string;

  verifiedPhoneNumbers: string[];
}

interface StatementActions {
  setSelectedProduct: (product: StatementState['selectedProduct']) => void;
  setSubject: (subject: StatementState['subject']) => void;
  setCalculatorInfo: (calculatorInfo: StatementState['calculatorInfo']) => void;
  setCreditInfoAccepted: (accepted: StatementState['creditInfoAccepted']) => void;
  setSubjectStepValidity: (valid: StatementState['subjectStepValid']) => void;
  setConfirmationStepValidity: (valid: StatementState['confirmationStepValid']) => void;
  setStatement: (statement: StatementState['statement']) => void;
  setCalculatorStepSkipped: (skipped: boolean) => void;
  setComment: (comment: string) => void;
  addVerifiedPhoneNumber: (number: string) => void;
}

export const useStatementStore = create<StatementState & StatementActions>((set) => ({
  selectedProduct: null,
  subject: {
    legalType: SubjectLegalType.INDIVIDUAL,
    nationalId: '',
    senderNationalId: '',
    firstname: '',
    lastname: '',
    name: '',
    email: undefined,
    phone: '',
  },
  calculatorInfo: {
    currency: 'GEL',
    assetsValue: 0,
    advanceRate: 0,
    numberOfPayments: 0,
    issuanceFee: 0,
    principalAmount: 0,
    issuanceRate: 0,
  },
  comment: '',

  statement: null,
  creditInfoAccepted: true,
  subjectStepValid: undefined,
  confirmationStepValid: false,
  calculatorStepValid: false,
  calculatorStepSkipped: false,

  verifiedPhoneNumbers: [],

  setSelectedProduct: (product) =>
    set((state) => ({
      selectedProduct: product,
      calculatorInfo: {
        ...state.calculatorInfo,
        currency: product?.currencies.includes('GEL') ? 'GEL' : product?.currencies[0] ?? 'GEL',
      },
    })),
  setSubject: (subject) => set(() => ({ subject: subject })),
  setCalculatorInfo: (calculatorInfo) =>
    set(() => ({
      calculatorInfo: calculatorInfo,
      calculatorStepValid: calculatorInfo.monthlyPayment !== undefined,
    })),
  setSubjectStepValidity: (valid) => set(() => ({ subjectStepValid: valid })),
  setConfirmationStepValidity: (valid) => set(() => ({ confirmationStepValid: valid })),
  setCreditInfoAccepted: (accepted) => set(() => ({ creditInfoAccepted: accepted })),
  setStatement: (statement) => set(() => ({ statement: statement })),
  setCalculatorStepSkipped: (skipped) => set(() => ({ calculatorStepSkipped: skipped })),
  setComment: (comment) => set(() => ({ comment: comment })),

  addVerifiedPhoneNumber: (number) =>
    set((state) => ({
      verifiedPhoneNumbers: [...state.verifiedPhoneNumbers, number],
    })),
}));
