import {Box, Card, CardContent, Stack} from '@mui/material';
import {useStatementStore} from './store';
import Typography from '@mui/material/Typography';
import {ZCheckbox} from '../../../shared/input/ZCheckbox';
import {useEffect, useState} from 'react';

export function SectionConfirmations() {
  const {setConfirmationStepValidity} = useStatementStore();

  const [cbCheck, setCbCheck] = useState(false);
  const [rsCheck, setRsCheck] = useState(false);
  const [siCheck, setSiCheck] = useState(false);

  useEffect(() => {
    if (cbCheck && rsCheck && siCheck) {
      setConfirmationStepValidity(true);
    } else {
      setConfirmationStepValidity(false);
    }
  }, [cbCheck, rsCheck, setConfirmationStepValidity, siCheck]);

  return (
    <>
      <Card variant={'outlined'} sx={{mt: 2, mx: 2}}>
        <CardContent>
          <Stack direction={'column'} spacing={6}>
            <div>
              <Typography variant={'h5'} mb={1}>
                თანხმობა საკრედიტო ბიუროში გადამოწმების შესახებ
              </Typography>
              <Typography>
                გამოვხატავ თანხმობას და უფლებას ვაძლევ სს მიკროსაფინანსო ორგანიზაცია „კრისტალს“,
                საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს წინამდებარე
                განაცხადში, კრედიტორთან დაცული, ასევე, ჩემ შესახებ საკრედიტო საინფორმაციო ბიუროებში
                დაცული და მათ მიერ მოწოდებული ინფორმაცია ჩემი, როგორც მომავალი
                მსესხებლის/თანამსესხებლის/ლიზინგის მიმღების/თავდების/უზრუნველყოფის საშუალების
                მიმწოდებლის გადახდისუნარიანობის ანალიზის მიზნით. გაცნობიერებული მაქვს საქართველოს
                კანონმდებლობით გათვალისწინებული უფლებები, რომ მონაცემთა სუბიექტის/მომხმარებლის
                მოთხოვნის შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს,
                დაამატოს, დაბლოკოს, წაშალოს ან გაანადგუროს მონაცემები, თუ ისინი არასრულია,
                არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება განხორციელდა
                კანონის საწინააღმდეგოდ. აღნიშნული თანხმობა მოქმედია ხელმოწერიდან/დადასტურებიდან 30
                სამუშაო დღის განმავლობაში (იურიდიული პირის შემთხვევაში 60 სამუშაო დღის
                განმავლობაში).
              </Typography>
            </div>

            <div>
              <Typography variant={'h5'} mb={1}>
                თანხმობა შემოსავლების სამსახურიდან ინფორმაციის დამუშავებაზე
              </Typography>
              <Typography>
                მომხმარებელი კრედიტორს ერთჯერადად ანიჭებს უფლებას საქართველოს ფინანსთა სამინისტროს
                სსიპ შემოსავლების სამსახურისგან მოითხოვოს და მიიღოს შემოსავლების სამსახურის
                მონაცემთა ერთიან ელექტრონულ ბაზაში (შემდგომში - „მონაცემთა ბაზა“) მისი შემოსავლების
                შესახებ დაცული ინფორმაცია. მოთხოვნის უფლება კრედიტორს წარმოეშობა მხოლოდ მაშინ, თუ
                მომხმარებლის დასაბეგრი შემოსავალი არ აღემატება 150 000 (ას ორმოცდაათი ათასი) ლარს.
                მონაცემთა დამუშავების მიზანია მომხმარებლის გადამხდელუნარიანობის შეფასება და
                აღნიშნული ინფორმაციადამუშავდება ფიზიკური პირის დაკრედიტების მიზნობრიობით საქართველოს
                კანონმდებლობი დადგენილი წესით. მომხმარებელი ასევე თანახმაა მისი მონაცემები ან/და
                თანხმობა გააზიაროს კრედიტორმა სსიპ შემოსავლების სამსახურთან ანგარიშსწორებისა და
                კანონმდებლობასთან შესაბამისობაში მონაცემების დამუშავების გადამოწმების მიზნით.
              </Typography>
            </div>

            <div>
              <Typography variant={'h5'} mb={1}>
                თანხმობა სერვისების განვითარების სააგენტოში გადამოწმების თაობაზე
              </Typography>
              <Typography>
                მომხმარებელი აცხადებს თანხმობას, რომ სს მიკროსაფინანსო ორგანიზაცია „კრისტალ“-მა
                კანონმდებლობით დადგენილი წესით, ფინანსური მომსახურების გაწევის მიზნითა და ამ მიზნის
                განსახორციელებლად საჭირო მოცულობით იდენტიფიცირება-ვერიფიცირებისათვის, სსიპ -
                სახელმწიფო სერვისების განვითარების სააგენტოს მონაცემთა ელექტრონული ბაზიდან მიიღოს და
                დაამუშაოს ჩემი პერსონალური მონაცემები, მათ შორის ფოტოსურათი.მომხმარებელი აცხადებს
                თანხმობას, რომ სს მიკროსაფინანსო ორგანიზაცია „კრისტალ“-მა კანონმდებლობით დადგენილი
                წესით, ფინანსური მომსახურების გაწევის მიზნითა და ამ მიზნის განსახორციელებლად საჭირო
                მოცულობით იდენტიფიცირება-ვერიფიცირებისათვის, სსიპ - სახელმწიფო სერვისების
                განვითარების სააგენტოს მონაცემთა ელექტრონული ბაზიდან მიიღოს და დაამუშაოს ჩემი
                პერსონალური მონაცემები, მათ შორის ფოტოსურათი.
              </Typography>
            </div>
          </Stack>
        </CardContent>
      </Card>
      <Stack direction={'column'} spacing={2} mt={2} mx={0}>
        <Box/>
        <ZCheckbox
          label={'ვეთანხმები საკრედიტო ბიუროში ინფორმაციის გადამოწმებას'}
          value={cbCheck}
          onChange={(_event, checked) => {
            setCbCheck(checked);
          }}
        />
        <ZCheckbox
          label={'ვეთანხმები შემოსავლების სამსახურიდან ინფორმაციის დამუშავებას'}
          value={rsCheck}
          onChange={(_event, checked) => {
            setRsCheck(checked);
          }}
        />
        <ZCheckbox
          label={'ვეთანხმები სერვისების განვითარების სააგენტოში ინფორმაციის გადამოწმებას'}
          value={siCheck}
          onChange={(_event, checked) => {
            setSiCheck(checked);
          }}
        />
      </Stack>
    </>
  );
}
