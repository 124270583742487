import { z, ZodType } from 'zod';

export function getStringToNumberPreprocessed(schema: ZodType) {
  return z.preprocess((input) => {
    const processed = z.string().transform(Number).safeParse(input);
    return processed.success ? processed.data : input;
  }, schema);
}

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const LETTER_ONLY_REGEX = /^([^0-9]*)$/
export const NUMBER_ONLY_REGEX = /^[0-9]+$/
