import { Controller } from 'react-hook-form';
import { FormInput } from './FormTextField';
import { ZSelectField, ZSelectFieldProps } from '../ZSelectField';

export function FormSelectField({ name, control, ...rest }: ZSelectFieldProps & FormInput) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <ZSelectField
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            {...rest}
          />
        );
      }}
    />
  );
}
